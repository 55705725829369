<template>
  <div>
    <div class="mx-4">
      <PageTitle title="Учебное управление" />

      <v-tabs
        v-if="isShowTabs"
        background-color="transparent"
        class="base-tabs custom-tabs-arrows"
        color="primary"
        show-arrows
      >
        <v-tab v-for="(tab, i) in tabsComputed" :key="i" :to="tab.to" class="tab">
          {{ tab.text }}
        </v-tab>
      </v-tabs>

      <v-container
        fluid
        class="pa-0"
        :class="isShowTabs ? 'mt-4' : 'mt-0'"
      >
        <router-view />
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'

export default {
  name: 'Management',

  components: {
    PageTitle
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.baseRedirect()
    this.dataLoaded = true
  },

  data: () => ({
    dataLoaded: false,

    tabs: [
      { text: 'Выходные дни', to: `/management/holidays` },
      { text: 'Выгрузка слушателей', to: `/management/uploading-listeners` },
      { text: 'Отчеты', to: `/management/reports` }
    ]
  }),

  computed: {
    ...mapGetters('user', ['isSuperAdmin', 'isAdmin', 'isAuditor', 'isDean', 'isTrainingManager']),

    tabsComputed() {
      if (this.isSuperAdmin || this.isAdmin || this.isAuditor) {
        return this.tabs
      } else {
        return this.tabs.filter((item, index) => index !== 1)
      }
    },

    isShowTabs() {
      return this.isSuperAdmin || this.isAdmin || this.isAuditor || this.isTrainingManager
    }
  },

  methods: {
    async baseRedirect() {
      if (this.isShowTabs) {
        this.$route.name !== 'holidays' && await this.$router.push({ name: 'holidays' })
      } else {
        this.$route.name !== 'reports' && await this.$router.push({ name: 'reports' })
      }
    }
  }
}
</script>